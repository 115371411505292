import React, { Component } from 'react'
import NewsletterForm from './NewsletterForm'

export default class Contact extends Component {
  render() {
    return (
      
      <>
        <h1>Tetap terhubung</h1>
        {/* <p>
          I write about JavaScript, programming, and front-end design. Get updates once or twice a
          month when I write an article.
        </p> */}
        {/* <NewsletterForm /> */}
        <p>Anda dapat menemukan saya di web.</p>
        <ul>
          <li>
            <strong>GitHub</strong>:{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/edytk">
              edytk
            </a>
          </li>
          <li>
            <strong>Twitter</strong>:{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/tama0x1">
              tama0x1
            </a>
          </li>
          <li>
            <strong>Telegram</strong>:{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://t.me/g3tux">
              g3tux
            </a>
          </li>
          <li>
            <strong>Feed</strong>: <a href="/rss.xml">RSS</a>
          </li>
        </ul>
      </>
    )
  }
}
